import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Select, Tabs, message } from "antd";
import { getReportConfigs } from "../../../apis/CompanyApis";
import { createFloor } from "../../../apis/floorPlannerApis";

const CreateFloorDialog = (props) => {
  const [form] = Form.useForm();
  const [floorOptions, setFloorOptions] = useState([]);
  const [filterFloorOptions, setFilterFloorOptions] = useState([]);

  useEffect(() => {
    fetchReportConfigs();
  }, [])

  const fetchReportConfigs = () => {
    getReportConfigs()
      .then((res) => {
        if (res.status === 200) {
          let options = res.data.map((item) => ({
            label: item.nameRule + " " + item.englishName,
            value: item.nameRule,
          }));
          setFloorOptions(options);
          setFilterFloorOptions(options);
        }
      })
      .catch((e) => {
        message.error("Fetch Floors Failed" + e);
      });
  }

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 17,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
  };


  const onFinish = (values) => {
    console.log(JSON.stringify(values))
    createFloor(props.floorPlannerProjectId, values)
      .then((res) => {
        console.log(JSON.stringify(res))
        if (res.status === 200) {
          message.success("Create Floor Successfully")
          props.setVisible(false);
        } else {
          message.error("Create Floor Failed")
        }
      })
      .catch((e) => {
        message.error("Create Floor Failed")
      });
  }
  return (
    <React.Fragment>
      <Form form={form}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          label="FloorName"
          name="names"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="Select"
            dropdownStyle={{ zIndex: 10000 }}
            showSearch
            mode="multiple"
            filterOption={false} // Disable default filtering
            onSearch={(value) => {
              const filteredOptions = floorOptions.filter(
                (item) =>
                  item.value.toLowerCase().includes(value.toLowerCase()) ||
                  item.label.toLowerCase().includes(value.toLowerCase())
              );
              setFilterFloorOptions(filteredOptions);
            }}
            options={filterFloorOptions}
            optionLabelProp="label"
            dropdownRender={(menu) => (
              <div style={{
                maxHeight: "400px", // Set max height for the dropdown
                overflowY: "auto", // Add a scroll bar if needed
                border: "1px solid #ccc",
                padding: "5px 0",
                boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
              }}>
                {filterFloorOptions.map((item) => (
                  <div
                    key={item.value}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      form.setFieldValue("names", [
                        ...(form.getFieldValue("names") || []),
                        item.value,
                      ])
                    }
                  >
                    <span>{item.value}</span>
                    <span style={{ color: "#888" }}>
                      {item.label.replace(`${item.value} `, "")}
                    </span>
                  </div>
                ))}
              </div>
            )}
          />
        </Form.Item>


        <Form.Item>
          <Button
            style={{
              width: "80%",
              marginLeft: "30%",
            }}
            type="primary"
            onClick={(e) => {
              form.validateFields().then(() => {
                onFinish(form.getFieldsValue());
              }).catch(errorInfo => {
              });
            }
            }
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};
export default CreateFloorDialog;
