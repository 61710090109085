import { Button, Checkbox, Form, Radio, Select, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  export3DProjectOnFloorPlanner,
  exportProjectOnFloorPlanner,
} from "../../../../apis/floorPlannerApis";
import "./params2dForm.scss";
import { confirmDialog } from "primereact/confirmdialog";
import { useRef } from "react";
import { Option } from "antd/lib/mentions";

const Params3dForm = (props) => {
  const [fileFormat, setFileFormat] = useState("jpg");
  const [includeFML, setIncludeFML] = useState(false);
  const [views, setViews] = useState([]);
  const [form] = Form.useForm();
  const toast = useRef(null);

  useEffect(() => {
    form.setFieldsValue(
      { floors: props.selectedFloors }
    )

  }, [props.selectedFloors])

  const onFinish = (values) => {
    var exportParams = {
      ...values,
      views: views,
    };
    export3DProjectOnFloorPlanner(
      props.floorPlannerProjectId,
      props.projectId,
      exportParams
    ).then((response) => {
      if (response.status === 200) {
        props.projectImport();
        message.success("Project imported successfully");
      }
    });
    props.visible(false);
  };

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 17,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
  };

  const onSwitchChange = (checked) => {
    setIncludeFML(checked);
  };

  const exportProjectonnFloorPlanner = (values) => {
    exportProjectOnFloorPlanner(
      props.floorPlannerProjectId,
      props.projectId,
      {floors: values.floors,
        visual: values.visual
      }
    ).then((response) => {
      if (response.status === 200) {
        props.projectImport();
        message.success("Project imported successfully");
      }
    });
    props.visible(false);
    message.info("Project import is processing!");
  };

  const options = [
    {
      label: "Top View",
      value: "top",
    },
    {
      label: "Tilted View",
      value: "tilted",
    },
    {
      label: "Northeast",
      value: "ne",
    },

    {
      label: "Nothwest",
      value: "nw",
    },
    {
      label: "Southeast",
      value: "se",
    },
    {
      label: "Southwest",
      value: "sw",
    },
  ];

  const onaCheckBoxChange = (checkedValues) => {
    setViews([]);
    var view = [];
    checkedValues.forEach((checkedValue) => {
      view.push(checkedValue);
    });
    setViews(view);
  };

  return (
    <React.Fragment>
      <Form form={form}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          name="orientation"
          label="Orientation"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group className="radio-btns">
            <Radio.Button value="landscape">Landscape</Radio.Button>
            <Radio.Button value="portrait">Portrait</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Include FML" value={includeFML}>
          <Switch className="radio-btns" onChange={onSwitchChange} />
        </Form.Item>
        <Form.Item name="exportIn3d" label="Export in 3d">
          <Checkbox.Group
            className="radio-btns"
            options={options}
            onChange={onaCheckBoxChange}
          />
        </Form.Item>
        <Form.Item
          label="Color mode"
          name="visual"
        >
          <Select
            onChange={(e) => {
            }}
            style={{ width: 175 }}
            placeholder="Select"
            defaultValue={"ALL"}
            dropdownStyle={{ zIndex: 10000 }}>
            <Option value="BW">Black and white</Option>
            <Option value="BWC">Color</Option>
            <Option value="ALL">Material</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Floors" name="floors">
          <Select
            mode="multiple"
            onChange={(e) => {
            }}
            options={props.floorOptions}
            style={{ width: 175 }}
            placeholder="Select"
            dropdownStyle={{ zIndex: 10000 }}>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            style={{
              // marginTop: "11rem",
              width: "80%",
              marginLeft: "28%",
            }}
            type="primary"
            onClick={(e) => {
              form.validateFields().then(() => {
                confirmDialog({
                  message: "Are you sure you want to import?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => onFinish(form.getFieldsValue()),
                });
              }).catch(errorInfo => {
                toast.current.show({
                  severity: "info",
                  summary: "Status",
                  detail: errorInfo,
                  life: 5000,
                });
              });
            }
            }
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <div>
        {props.containsNEN && (
          <p style={{ color: "red", fontSize: 11, marginLeft: 70, marginBottom: 10 }}>
            Project contains NEN floor. It will be removed when importing the total project.
          </p>
        )}
      </div>
      <Button
        type="primary"
        style={{
          width: "57%",
          marginLeft: "20%",
        }}
        onClick={(e) => {
          confirmDialog({
            message: "Are you sure you want to import?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => exportProjectonnFloorPlanner(form.getFieldsValue()),
          });
        }
        }      >
        Import total project
      </Button>
    </React.Fragment>
  );
};

export default Params3dForm;
