import { values } from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import {
    updateOrderReportConfig,
    getReportConfigs
} from "./../../apis/CompanyApis";
import { Button } from "primereact/button";
import AddReportConfig from "./dialog/AddReportConfig";
const ReportOrderComponent = (props) => {
    const [visibleAddReportConfigDialog, setVisibleAddReportConfigDialog] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [reportInfos, setReportInfos] = useState([]);
    const [reportInfo, setReportInfo] = useState(null)
    const toast = useRef(null);
    const columns = [
        { field: 'orderId', header: 'Id' },
        { field: 'name', header: 'Floor Name Dutch' },
        { field: 'nameRule', header: 'Floor Name Rule' },
        { field: 'manualName', header: 'Manual Name'},
        { field: 'englishName', header: 'English Name'},
    ];

    useEffect(() => {
        fetchReportConfigs();
    }, [])

    const fetchReportConfigs = () => {
        setApiLoading(true);
        getReportConfigs()
            .then((res) => {
                if (res.status === 200) {
                    let newArray = res.data.map((item, index) => ({ ...item, orderId: index + 1 }));
                    setReportInfos(newArray);
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: "Get report configs failed",
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    }

    const updateReportConfigs = (values) => {
        let infos = values.map((item, index) => ({ ...item, id: null }));

        setApiLoading(true);
        updateOrderReportConfig(infos)
            .then((res) => {
                if (res.status === 200) {
                    let infos = res.data.map((item, index) => ({ ...item, orderId: index + 1 }));
                    setReportInfos(infos);
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: "Update report configs failed",
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    }

    const dynamicColumns = columns.map((col, i) => {
        return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} />;
    });

    const reorder = (values) => {
        updateReportConfigs(values);
    }

    const changeVisibleAddReportConfigDialog = (bool) => {
        setApiLoading(false);
        fetchReportConfigs();
        setVisibleAddReportConfigDialog(bool);
        setReportInfo(null)
    };

    return (
        <>
            <div className="user-custom-style mt-5">
                <div>
                    <Toolbar
                        className="mb-4"
                        left={() => {
                            return (
                                <React.Fragment>
                                    <div>
                                        <h3 className="m-0">Order Of Report</h3>
                                    </div>
                                </React.Fragment>
                            );
                        }}
                        right={() => {
                            return (
                                <React.Fragment>
                                    <Button
                                        style={{
                                            backgroundColor: "#037EEA",
                                            borderColor: "#037EEA",
                                        }}
                                        icon={"pi pi-plus"}
                                        label="Create Report"
                                        onClick={(event) => {
                                            setVisibleAddReportConfigDialog(true);
                                        }}
                                    />
                                </React.Fragment>
                            );
                        }}
                    ></Toolbar>
                    <DataTable
                        value={reportInfos}
                        onSelectionChange={(e) => { }}
                        dataKey="id"
                        responsiveLayout="scroll"
                        onRowReorder={(e) =>
                            reorder(e.value)}
                        reorderableColumns reorderableRows
                        size={"small"}>
                        <Column rowReorder style={{ width: '3rem' }} />
                        {dynamicColumns}
                        <Column header="Update" body={(record) => {
                            return (
                                <React.Fragment>
                                    <div className="flex justify-content-center">
                                        {
                                            <div>
                                                <Button
                                                    className="p-button-rounded p-button-info"
                                                    style={{
                                                        backgroundColor: "#037EEA",
                                                        borderColor: "#037EEA",
                                                    }}
                                                    icon={"pi pi-pencil"}
                                                    onClick={(event) => {
                                                        setReportInfo(record)
                                                        setVisibleAddReportConfigDialog(true);                                                
                                                    }}
                                                />
                                            </div>

                                        }
                                    </div>
                                    
                                </React.Fragment>
                            );
                        }}
                        />
                        <Column header="Action" body={(record) => {
                            return (
                                <React.Fragment>
                                    <div className="flex justify-content-center">
                                        {
                                            <div>
                                                <Button
                                                    className="p-button-rounded p-button-info"

                                                    style={{
                                                        backgroundColor: "#037EEA",
                                                        borderColor: "#037EEA",
                                                    }}
                                                    icon={"pi pi-trash"}
                                                    onClick={(event) => {
                                                        let infos = reportInfos;
                                                        let index = reportInfos.indexOf(record);
                                                        infos.splice(index, 1);
                                                        updateReportConfigs(infos);
                                                    }}
                                                />
                                            </div>

                                        }
                                    </div>
                                    
                                </React.Fragment>
                            );
                        }}
                        />
                    </DataTable>
                    <AddReportConfig
                        visible={visibleAddReportConfigDialog}
                        setVisible={changeVisibleAddReportConfigDialog}
                        updateReport={reportInfo}
                    />
                </div>
            </div>
        </>
    )
}

export default ReportOrderComponent;