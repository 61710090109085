import React, { useEffect, useRef, useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import {
    getAllCustomValues,
    updateCustomValue
} from "../../apis/AdminApis";

import {
    Spin
} from "antd";
import UpdateCustomValueDialog from "./dialog/UpdateCustomValueDialog";


const CustomValue = () => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [customValues, setCustomValues] = useState([]);
    const [customValue, setCustomValue] = useState(null);
    const [dialogMode, setDialogMode] = useState("Create");
    const [visibleUpdateCustomValueDialog, setVisibleUpdateCustomValueDialog] = useState(false);

    useEffect(() => {
        fetchCustomValues();
    }, [])

    const fetchCustomValues = () => {
        setApiLoading(true);
        getAllCustomValues()
            .then((res) => {
                if (res.status === 200) {
                    setCustomValues(res.data);
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: res.response.data,
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    }

    const changeVisibleCustomValueDialog = (visible) => {
        setVisibleUpdateCustomValueDialog(visible);
        fetchCustomValues();
        setCustomValue(null);
    }
    return (
        <React.Fragment>
            <div className="user-custom-style">
                <Toast ref={toast} />
                <div>
                    <Spin spinning={apiLoading}>
                          <Toolbar
                                        className="mb-4"
                                        left={() => {
                                          return (
                                            <React.Fragment>
                                              <div>
                                                <h3 className="m-0">Global Config</h3>
                                              </div>
                                            </React.Fragment>
                                          );
                                        }}
                                        right={() => {
                                          return (
                                              <React.Fragment>
                                                  <Button
                                                      style={{
                                                          backgroundColor: "#037EEA",
                                                          borderColor: "#037EEA",
                                                      }}
                                                      icon={"pi pi-plus"}
                                                      label="Create New Config"
                                                      onClick={(event) => {
                                                        setCustomValue(null);
                                                        setDialogMode("Create");
                                                        setVisibleUpdateCustomValueDialog(true);
                                                      }}
                                                  />
                                              </React.Fragment>
                                          );
                                      }}
                                      ></Toolbar>
                        <DataTable
                            value={customValues}
                            dataKey="id"
                            responsiveLayout="scroll"
                        >
                            <Column field="key" header="Key" />
                            <Column field="value" header="Value" />
                            <Column header="Action" body={(record) => {
                                return (
                                    <React.Fragment>
                                        <div className="flex justify-content-center">
                                            {
                                                <div>
                                                    <Button
                                                        style={{
                                                            backgroundColor: "#037EEA",
                                                            borderColor: "#037EEA",
                                                        }}
                                                        icon={"pi pi-pencil"}
                                                        label="Edit"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            setCustomValue(record);
                                                            setVisibleUpdateCustomValueDialog(true);
                                                            setDialogMode("Update")
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                            />
                        </DataTable>
                    </Spin>
                </div>
            </div>
            <UpdateCustomValueDialog
                customValue={customValue}
                dialogMode={dialogMode}
                visible={visibleUpdateCustomValueDialog}
                setVisible={changeVisibleCustomValueDialog}
            >
            </UpdateCustomValueDialog>
        </React.Fragment>
    );
};

export default CustomValue;
