import React, { useEffect, useState } from "react";
import { Form, Select, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import Params2dForm from "../form/exportParamsForms/params2dForm";
import Params3dForm from "../form/exportParamsForms/params3dForm";
import BackgroundParams from "../form/exportParamsForms/backgroundForm";

const ExportProjectParams = (props) => {
  const [floorOptions, setFloorOptions] = useState([]);
  const [containsNEN, setContainsNEN] = useState(false);

  useEffect(() => {
    const options = props.listFloors?.map((floor) => ({
      label: floor,
      value: floor,
    }));   
     
    setFloorOptions(options);
    setContainsNEN(props.listFloors?.some(option => option.includes("NEN")));
  }, [props.listFloors]);
  
  return (
    <React.Fragment>
      <Tabs defaultActiveKey="1" centered tabBarStyle={{ height: "100%" }}>
        <TabPane tab={<span>2D </span>} key="1">
          <Params2dForm
            projectImport={props.projectImport}
            floorPlannerProjectId={props.floorPlannerProjectId}
            projectId={props.projectId}
            floorOptions={floorOptions}
            containsNEN={containsNEN}
            selectedFloors={props.floors}
            dropdownStyle={{zIndex: 10001}}
            visible={props.visible}
          />
        </TabPane>
        <TabPane tab={<span>3D </span>} key="2">
          <Params3dForm
            projectImport={props.projectImport}
            floorPlannerProjectId={props.floorPlannerProjectId}
            projectId={props.projectId}
            floorOptions={floorOptions}
            containsNEN={containsNEN}
            selectedFloors={props.floors}
            visible={props.visible}
          />
        </TabPane>
        <TabPane tab={<span>Background </span>} key="3">
          <BackgroundParams
            projectImport={props.projectImport}
            floorPlannerProjectId={props.floorPlannerProjectId}
            projectId={props.projectId}
            floorOptions={floorOptions}
            containsNEN={containsNEN}
            selectedFloors={props.floors}
            visible={props.visible}
          />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};
export default ExportProjectParams;
