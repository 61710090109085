import { Form, Spin, Input, Select, Checkbox } from "antd";
import { Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import {
    updateCustomValue
} from "../../../apis/AdminApis";
import TextArea from "antd/lib/input/TextArea";

const UpdateCustomValueDialog = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (props?.dialogMode === "Update") {
            form.setFieldsValue({ ...props.customValue })
        } else {
            form.resetFields();
        }

    }, [props]);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
                let customValue = {
                    id: props?.customValue?.id,
                    editable: props?.customValue?.editable == null ? false : props?.customValue?.editable,
                    ... values
                }

                setApiLoading(true);
                updateCustomValue(customValue).then((res) => {
                    if (res.status === 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Status",
                            detail: props.dialogMode + " Global Config successfully",
                            life: 3000
                        })

                        setApiLoading(false);
                        props.setVisible(false);
                    } else {
                        toast.current.show({
                            severity: "warn",
                            summary: "Status",
                            detail: props.dialogMode + " Global Config failed!",
                            life: 3000
                        })
                        setApiLoading(false);
                        props.setVisible(false);
                    }
                }).catch((e) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: e,
                        life: 3000,
                    });
                    setApiLoading(false);
                });
        })

    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Dialog
                header={props.dialogMode + " Global Config"}
                visible={props.visible}
                onHide={() => { props.setVisible(false) }}
            >
                <Spin spinning={apiLoading} >
                    <React.Fragment>
                        <div className='flex justify-content-between align-items-center'>
                            <Form form={form} onFinish={handleSubmit}>
                                <Form.Item required={true}
                                    name="key" label="Key"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        readOnly = {!props.customValue?.editable || props.dialogMode === "Create"}
                                        style={{ width: "300px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={true}
                                    name="value" label="Value"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "300px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>

                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <PrimeBtn
                                        style={{
                                            backgroundColor: "#037eea",
                                            borderColor: "#037eea",
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        type="submit"
                                        label="Submit"
                                    />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                </Spin>
            </Dialog>
        </React.Fragment>
    )
}

export default UpdateCustomValueDialog
