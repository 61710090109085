import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import inProgress from "./../../resources/in-progress.svg";
import ready from "./../../resources/ready.svg";
import { Tooltip } from "primereact/tooltip";

import {
  ACCEPTED,
  ASSIGNED,
  ADJUSTMENTS_REQUESTED,
  IN_PROGRESS,
  MAKE_CHANGES,
  QUALITY_CHECK,
  FINAL_CHECK,
  SUBMITTED,
  ASSIGN,
  compareEta,
  DELIVERED,
  etaDateDisplay,
  PROJECT_IDS,
  conditionToForDeliveredAll
} from "../../utils/constants";
import { Tag } from "primereact/tag";
import "./style.scss";
import { Popover } from "antd";
import { Button } from "primereact/button";

const ProjectList = (props) => {
  // Function to add tooltip text to each row
  const rowClassName = (rowData) => {
    return {
      [`tooltip-row-${rowData.projectId}`]: true, // Unique class per row
      'p-datatable-row-hover': true // Ensures hover effect
    };
  };

  return (
    <div className="card">
      {props?.tableData?.map((row) => {
        // Determine if priorityCustomer should be true
        const showMessage =
          (props?.currentUser?.userType !== "Customer" && row.projectMessage != null) ||
          (props?.currentUser?.userType === "Customer" && row.customerMessage != null);
        return (
          <Tooltip
            key={row?.projectId}
            target={`.tooltip-row-${row?.projectId}`} // Unique class for each row
            content={showMessage ? (props.currentUser?.userType !== "Customer" ? `${row?.projectMessage}` : `${row?.customerMessage}`) : ""} // Conditional Tooltip
            position="top left"
          />
        );
      })}
      <DataTable
        rowHover // Enables row hover effect
        rowClassName={rowClassName}
        value={props.tableData}
        rows={props.numRow}
        paginator
        responsiveLayout="scroll"
        header={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>{props.tableName}</p>
            {props.tileName === "accepted" && props.allTilesCount["accepted"] ? (
              <div style={{ display: "flex" }}>
                {
                  conditionToForDeliveredAll(props.permissions) && props.showDeliveredAll ?
                    <Button
                      style={{ backgroundColor: "#037eea", marginRight: "10px" }}
                      icon="pi pi-file"
                      label="Delivered All"
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDialog({
                          message: "Are you sure you want to proceed?",
                          header: "Confirmation",
                          icon: "pi pi-exclamation-triangle",
                          accept: () => props.handleDeliveredAllProjects(),
                        });
                      }}
                    /> : ("")
                }
                <Button
                  style={{ backgroundColor: "#037eea" }}
                  icon="pi pi-file"
                  label="Export All"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.exportAllProjectData("accepted");
                  }}
                />
              </div>
            ) : ""}
          </div>
        }
        selectionMode="single"
        onRowClick={(e) => {
          window.localStorage.setItem(PROJECT_IDS, props.tableData.map(t => t.id));
          props.history.push({
            pathname: `${e.data.id}/projectEdit`,
            state: {
              id: e.data.id,
              agencyFormat: e.data.agency.agencyFormat,
            },
          });
        }}
      >
        <Column
          field="projectId"
          header={<span>ID</span>}
          body={(rowData) => (
            props.tableName !== "Priority Projects" ? (
              <span style={{ whiteSpace: "nowrap" }}>
                {rowData?.overdueProject ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    ! {rowData.projectId}
                  </span>
                ) : (
                  rowData.projectId
                )}
              </span>
            ) : (
              <span style={{ whiteSpace: "nowrap" }}>
                <span style={{ color: "red", fontWeight: "bold" }}>! </span>
                {rowData.projectId}
              </span>
            )
          )}
        />

        <Column
          field="street"
          header="Project Adress"
          body={(record) => {
            return (
              <React.Fragment>
                {record &&
                  (record.street != null ||
                    record.number != null ||
                    record.city != null)
                  ? record.street +
                  " " +
                  record.number +
                  " " +
                  record.city
                  : ""}
              </React.Fragment>
            );
          }}
        />
        <Column
          header="Rework Type"
          body={(record) => {
            if (record?.parentId != null)
              return (
                <React.Fragment>
                  Rework
                </React.Fragment>
              );
            else if (record?.reworkProjects?.length > 0)
              return <React.Fragment>
                Origin
              </React.Fragment>
            else
              return <React.Fragment>
              </React.Fragment>
          }}
        ></Column>
        <Column
          // field="importStatus"
          header="Import Status"
          body={(record) => {
            if (
              record?.import2d === 0 &&
              record?.import3d === 0 &&
              record?.importBackground === 0
            )
              return <React.Fragment></React.Fragment>;
            else if (
              record?.import2d === 1 ||
              record?.import3d === 1 ||
              record?.importBackground === 1
            )
              return (
                <React.Fragment>
                  <img width={25}
                    src={inProgress}
                    alt="In Progress"
                  />
                </React.Fragment>
              );
            else if (
              record?.import2d === 2 &&
              record?.import3d === 2 &&
              record?.importBackground === 2
            )
              return (
                <React.Fragment>
                  <img width={25}
                    src={ready}
                    alt="Ready"
                  />
                </React.Fragment>
              );
          }}
        ></Column>
        <Column
          field="customer"
          header="Owner"
          body={(record) => {
            return (
              <React.Fragment>{record.customer?.name}</React.Fragment>
            );
          }}
        />
        <Column
          field="manager"
          header="Manager"
          body={(record) => {
            return (
              <React.Fragment>{record.manager?.name}</React.Fragment>
            );
          }}
        />
        <Column
          field="companyName"
          header="Customer"
          body={(record) => {
            return (
              <React.Fragment>{record.company?.name}</React.Fragment>
            );
          }}
        />
        <Column
          field="employee"
          header="Contractor"
          body={(record) => {
            return (
              <React.Fragment>{record.employee?.name}</React.Fragment>
            );
          }}
        />
        <Column
          field="qualityChecker"
          header="Quality Checker"
          body={(record) => {
            return (
              <React.Fragment>
                {record?.qualityChecker?.name}
              </React.Fragment>
            );
          }}
        />
        <Column
          field="houseType"
          header="House Type"
          body={(record) => {
            return <React.Fragment>{record?.houseType}</React.Fragment>;
          }}
        />
        <Column
          field="ndd"
          header="NDD"
          body={(record) => {
            return record?.ndd === "Yes" ? (
              <React.Fragment>
                <div style={{ border: "2px solid red" }}>
                  <b>{record?.ndd}</b>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>{record?.ndd}</React.Fragment>
            );
          }}
        />
        <Column
          field="autocad"
          header="Is AutoCad"
          body={(record) => {
            return record?.agency?.format === "Autocad" ? (
              <React.Fragment>
                <div style={{ border: "2px solid red", width: "27px" }}>
                  <b>Yes</b>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>No</React.Fragment>
            );
          }}
        />
        <Column
          field="projectStatus"
          header="Status"
          body={(record) => {
            switch (record?.projectStatus) {
              case SUBMITTED:
                return (
                  <React.Fragment>
                    <Tag
                      style={{ width: "125px" }}
                      className="mr-2 text-lg"
                      severity="info"
                      value="Submitted"
                    ></Tag>
                  </React.Fragment>
                );
              case IN_PROGRESS:
                return (
                  <React.Fragment>
                    <Tag
                      style={{ width: "125px" }}
                      className="mr-2 text-lg"
                      value="In Progress"
                    ></Tag>
                  </React.Fragment>
                );
              case FINAL_CHECK:
                return (
                  <React.Fragment>
                    <Tag
                      style={{
                        width: "125px",
                        backgroundColor: "#C79805",
                      }}
                      className="mr-2 text-lg"
                      value="Final Check"
                    ></Tag>
                  </React.Fragment>
                );
              case MAKE_CHANGES:
                return (
                  <React.Fragment>
                    <Tag
                      style={{ width: "125px" }}
                      className="mr-2 text-lg"
                      severity="danger"
                      value="Make Changes"
                    ></Tag>
                  </React.Fragment>
                );
              case ACCEPTED:
                return (
                  <React.Fragment>
                    <Tag
                      style={{
                        width: "125px",
                        backgroundColor: "#059BB4",
                      }}
                      className="mr-2 text-lg"
                      severity="success"
                      value="Accepted"
                    ></Tag>
                  </React.Fragment>
                );
              case QUALITY_CHECK:
                return (
                  <React.Fragment>
                    <Tag
                      style={{ width: "125px" }}
                      className="mr-2 text-lg"
                      severity="warning"
                      value="Quality Check"
                    ></Tag>
                  </React.Fragment>
                );
              case ASSIGNED:
                return (
                  <React.Fragment>
                    <Tag
                      style={{ width: "125px" }}
                      className="mr-2 text-lg"
                      severity="info"
                      value="Assigned"
                    ></Tag>
                  </React.Fragment>
                );

              case ASSIGN:
                return (
                  <React.Fragment>
                    <Tag
                      style={{ width: "125px" }}
                      className="mr-2 text-lg"
                      severity="info"
                      value="Assign"
                    ></Tag>
                  </React.Fragment>
                );
              case ADJUSTMENTS_REQUESTED:
                return (
                  <React.Fragment>
                    <Tag
                      style={{ width: "125px" }}
                      className="mr-2 text-lg"
                      severity="danger"
                      value="Adjustments Requested"
                    ></Tag>
                  </React.Fragment>
                );
              case DELIVERED:
                return (
                  <React.Fragment>
                    <Tag
                      style={{ width: "125px" }}
                      className="mr-2 text-lg"
                      severity="success"
                      value="Delivered"
                    ></Tag>
                  </React.Fragment>
                );
              default:
                break;
            }
          }}
        />
        <Column field="measurementDate" header="Measurement Date" />
        <Column
          field="eta"
          header="ETA "
          body={(record) => {
            return compareEta(
              record,
              etaDateDisplay(props.currentUser, record)
            );
          }}
        />
        <Column
          field="action"
          header="Action"
          body={(record) => {
            return (
              <React.Fragment>
                <div className="flex justify-content-center">
                  <div>
                    <Popover
                      placement="leftBottom"
                      content={() => props.content(record)}
                    >
                      <Button
                        type="button"
                        icon="pi pi-ellipsis-v"
                        aria-haspopup
                        aria-controls="overlay_panel"
                        className="select-product-button"
                        style={{ backgroundColor: "#037eea" }}
                      />
                    </Popover>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        />
      </DataTable>
    </div>
  )
}

export default ProjectList
