import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const createProjectOnFloorPlanner = (projectId, name) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/floorplanner/project/" + projectId + "/" + name,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const exportProjectOnFloorPlanner = (
  floorPlannerProjectId,
  projectId,
  exportParams
) => {
  return axios.post(
    APP_CONTEXT_URL +
      "/v1/floorplanner/exportproject/" +
      floorPlannerProjectId +
      "/" +
      projectId,
      exportParams,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const getListFloors = (
  floorPlannerProjectId, projectId) => {
  return axios.get(
    APP_CONTEXT_URL +
      "/v1/floorplanner/listFloors/" +
      floorPlannerProjectId +
      "/" +
      projectId,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const export2DProjectOnFloorPlanner = (
  floorPlannerProjectId,
  projectId,
  exportParams
) => {
  return axios.post(
    APP_CONTEXT_URL +
      "/v1/floorplanner/export2Dproject/" +
      floorPlannerProjectId +
      "/" +
      projectId,
    exportParams,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const export3DProjectOnFloorPlanner = (
  floorPlannerProjectId,
  projectId,
  exportParams
) => {
  return axios.post(
    APP_CONTEXT_URL +
      "/v1/floorplanner/export3Dproject/" +
      floorPlannerProjectId +
      "/" +
      projectId,
    exportParams,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const createFloor = (
  floorPlannerProjectId,
  floorParams
) => {
  return axios.post(
    APP_CONTEXT_URL +
      "/v1/floorplanner/createFloor/" +
      floorPlannerProjectId,
    floorParams,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const exportBackgroundProjectOnFloorPlanner = (
  floorPlannerProjectId,
  projectId,
  includeFML,
  exportParams,
) => {
  return axios.post(
    APP_CONTEXT_URL +
      "/v1/floorplanner/exportBackgroundProject/" +
      floorPlannerProjectId +
      "/" +
      projectId +
      "/" +
      includeFML,
      exportParams,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const addFloorPlannerId = (
  projectId,
  floorPlannerProjectId,
) => {
  return axios.get(
    APP_CONTEXT_URL +
      "/v1/floorplanner/saveFloorPlannerId/" +
      floorPlannerProjectId +
      "/" +
      projectId,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};
