import React, { useEffect, useRef, useState } from "react";
import { Popover, Spin } from "antd";
import {
  acceptedProject,
  changesRequestedProject,
  deliveredProjects,
  getAllProjects,
  getAllProjectsCount,
  makeChangesProject,
  managerAcceptProject,
  finalCheckProject,
  searchProject,
  searchProjectCount,
  submitProject,
  deliveredToAdjustmentRequired,
  copyProject
} from "../../../apis/ProjectApis";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { Toolbar } from "primereact/toolbar";
import { confirmDialog } from "primereact/confirmdialog";
import inProgress from "./../../../resources/in-progress.svg";
import ready from "./../../../resources/ready.svg";

import {
  ACCEPTED,
  ASSIGNED,
  ADJUSTMENTS_REQUESTED,
  IN_PROGRESS,
  MAKE_CHANGES,
  NDD,
  QUALITY_CHECK,
  FINAL_CHECK,
  SUBMITTED,
  ASSIGN,
  compareEta,
  DELIVERED,
  conditionForProjectToFinalCheckForQualityCheck,
  conditionToManagerAcceptProject,
  conditionToAssignProjectCoodinator,
  conditionToProjectAssignToContractor,
  conditionToProjectReAssignToContractor,
  conditionToProjectAssignToQualityChecker,
  conditionToProjectFinalCheckForCustomerReview,
  conditionToReturnToFinalCheck,
  conditionToProjectForMakeChanges,
  conditionToProjectForAccept,
  conditionToProjectForRequestChanges,
  conditionToProjectForDelivered,
  etaDateDisplay,
  conditionToProjectForAdjustmentRequired,
  PROJECT_IDS,
  conditionToCopyProject
} from "../../../utils/constants";
import "./style.scss";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import AssignToContractorDialog from "../dialog/assigntocontractordialog";
import AssigntoqualityCheckerDialog from "../dialog/assigntoqualitycheckerdialog";
import AssignToManagerDialog from "../dialog/assigntomanagerdialog";
import CustomerRequestChangeDialog from "../dialog/CustomerRequestChangesDialog";
import FeedbackDialog from "../dialog/FeedbackDialog";
import { Tooltip } from "primereact/tooltip";

const ProjectDetail = (props) => {
  const [projects, setProjects] = useState([]);
  const [basicFirst, setBasicFirst] = useState(0);
  const [totalRows, setTotalRows] = useState(30);
  const [pageNumber, setPageNumber] = useState(0);
  const [apiLoading, setApiLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [visibleContractorDialog, setVisibleContractorDialog] = useState(false);
  const [visibleQualityCheckerDialog, setVisibleQualityCheckerDialog] =
    useState(false);
  const [
    visibleCustomerRequestChangeDialog,
    setVisibleCustomerRequestChangeDialog,
  ] = useState(false);
  const [visibleFeedbackDialog, setVisibleFeedbackDialog] = useState(false);
  const [visibleManagerDialog, setVisibleManagerDialog] = useState(false);
  const [project, setProject] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const dt = useRef(null);
  const toast = useRef(null);
  const history = useHistory();
  // Function to add tooltip text to each row
  const rowClassName = (rowData) => {
    return {
      [`tooltip-row-${rowData.projectId}`]: true, // Unique class per row
      'p-datatable-row-hover': true // Ensures hover effect
    };
  };
  const loadProjects = (current, pageSize) => {
    setApiLoading(true);
    getAllProjects(current, pageSize)
      .then((response) => {
        if (response.status === 200) {
          setProjects(response.data);
          setApiLoading(false);
          window.localStorage.setItem(PROJECT_IDS, response.data.map(t => t.id));

        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: response.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  const onBasicPageChange = (event) => {
    setApiLoading(true);
    setBasicFirst(event.first);
    setPageNumber(event.page);
    setTotalRows(event.rows);
    setApiLoading(false);
  };

  const fetchProjectCount = () => {
    if (pageNumber >= 0 && totalRows >= 10) {
      setApiLoading(true);
      getAllProjectsCount()
        .then((res) => {
          if (res.status === 200) {
            setTotalRecords(res.data);
            loadProjects(pageNumber, totalRows);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchProjectCount();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [pageNumber, totalRows]);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleManagerAcceptProject = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      managerAcceptProject(record.id)
        .then(async (res) => {
          if (res.status === 200) {
            fetchProjectCount();
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Assigned Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleFinalCheckProject = (record, isAssignToMe) => {
    if (record && record.id) {
      setApiLoading(true);
      finalCheckProject(record.id, isAssignToMe)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Ready Successfully",
              life: 3000,
            });
            fetchProjectCount();
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };
  const handleDeliveredToAdjustmentRequired = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      deliveredToAdjustmentRequired(record.id)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Make Changes Successfully",
              life: 3000,
            });
            fetchProjectCount();
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleMakeChangesProject = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      makeChangesProject(record.id)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Make Changes Successfully",
              life: 3000,
            });
            fetchProjectCount();
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const customerAcceptProject = (record, isAssignToMe) => {
    if (record && record.id) {
      setApiLoading(true);
      acceptedProject(record.id, isAssignToMe)
        .then((res) => {
          if (res.status === 200) {
            fetchProjectCount();
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Accepted Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const customerChangesRequestedProject = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      changesRequestedProject(record.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProjectCount();
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail:
                "Project Status Change To Adjustments Required Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleSubmitProject = (project) => {
    if (project && project.id) {
      setApiLoading(true);
      submitProject(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProjectCount();
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Submitted For Quality Check Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const managerDeliverProject = (project) => {
    if (project && project.id) {
      setApiLoading(true);
      deliveredProjects(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProjectCount();
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Submitted For Quality Check Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleReturnToFinalCheck = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      finalCheckProject(record.id, false)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Final Check Successfully",
              life: 3000,
            });
            fetchProjectCount();
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleCopyProject = (project) => {
    if (project && project.id) {
      setApiLoading(true);
      copyProject(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProjectCount();
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Copy Successfully!",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };


  const changeVisibleCustomerRequestChangeDialogState = (bool) => {
    setApiLoading(false);
    fetchProjectCount();
    setProject(null);
    setVisibleCustomerRequestChangeDialog(bool);
  };

  const changeVisibleFeedbackDialogState = (bool) => {
    setApiLoading(false);
    fetchProjectCount();
    setVisibleFeedbackDialog(bool);
    if (project.projectStatus === FINAL_CHECK) {
      if (project.needUpdateAdjustment) {
        setApiLoading(true);
        setVisibleCustomerRequestChangeDialog(true);
      } else {
        if (project.manager == null) {
          confirmDialog({
            message: (
              <div>
                Do you want to assign yourself to Project Final Checker?
              </div>
            ),
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDialog({
              message: (
                <div>
                  Are you sure you want to change status to Accept?
                  {project.fmlContainNEN ? (
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      <br />
                      NEN is still in the project. Please update FML without NEN
                      <br />
                    </span>
                  ) : null}
                </div>

              ),
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              accept: () => customerAcceptProject(project, true),
            }),
            reject: () => confirmDialog({
              message: (
                <div>
                  Are you sure you want to change status to Accept?
                  {project.fmlContainNEN ? (
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      <br />
                      NEN is still in the project. Please update FML without NEN
                      <br />
                    </span>
                  ) : null}
                </div>

              ),
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              accept: () => customerAcceptProject(project, false),
            }),
          });
        } else {
          confirmDialog({
            message: (
              <div>
                Are you sure you want to change status to Accept?
                {project.fmlContainNEN ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    <br />
                    NEN is still in the project. Please update FML without NEN
                    <br />
                  </span>
                ) : null}
              </div>

            ),
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => customerAcceptProject(project, false),
          });
        }
      }
    } else {
      setProject(null);
    }
  };

  const content = (record) => {
    return (
      <div className="flex flex-column align-items-start gap-2">
        <div>
          <Button
            style={{ width: "120px" }}
            shape="round"
            className="p-button-outlined p-button-text text-left"
            label="View Details"
            onClick={(e) => {
              e.stopPropagation();
              window.localStorage.setItem(PROJECT_IDS, projects.map(t => t.id));
              history.push({
                pathname: `${record.id}/projectEdit`,
                state: {
                  id: record.id,
                  agencyFormat: record.agency.agencyFormat,
                },
              });
            }}
          />
        </div>

        {conditionForProjectToFinalCheckForQualityCheck(record, permissions) ? (
          <div>
            <Button
              style={{ width: "120px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Ready for Quality Check"
              onClick={(e) => {
                e.stopPropagation();
                confirmDialog({
                  message:
                    "Are you sure you want to submit the project for Quality Check?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => handleSubmitProject(record),
                });
              }}
            />
          </div>
        ) : (
          ""
        )}

        {/* {conditionToManagerAcceptProject(record, currentUser) ? (
          <React.Fragment>
            <div>
              <Button
                style={{ width: "120px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Assign to me"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDialog({
                    message: "Are you sure you want to proceed?",
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    accept: () => handleManagerAcceptProject(record),
                  });
                }}
              />
            </div>
          </React.Fragment>
        ) : (
          ""
        )}*/}

        {conditionToAssignProjectCoodinator(record, permissions) ? (
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label={
                record?.manager?.id
                  ? "Re-Assign Project Final Checker"
                  : "Assign Project Final Checker"
              }
              onClick={(e) => {
                e.stopPropagation();
                setProject(record);
                setApiLoading(true);
                setVisibleManagerDialog(true);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToProjectAssignToContractor(record, permissions) ? (
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Assign to Contractor"
              onClick={(e) => {
                e.stopPropagation();
                setProject(record);
                setApiLoading(true);
                setVisibleContractorDialog(true);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToProjectReAssignToContractor(record, permissions) ? (
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Re-Assign Contractor"
              onClick={(e) => {
                e.stopPropagation();
                setProject(record);
                setApiLoading(true);
                setVisibleContractorDialog(true);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToProjectAssignToQualityChecker(record, permissions) ? (
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label={
                record?.qualityChecker?.id
                  ? "Re-Assign Quality Checker"
                  : "Assign Quality Checker"
              }
              onClick={(e) => {
                e.stopPropagation();
                setProject(record);
                setApiLoading(true);
                setVisibleQualityCheckerDialog(true);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToProjectFinalCheckForCustomerReview(record, permissions) ? (
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Ready for Final Check"
              onClick={(e) => {
                e.stopPropagation();
                setProject(record);
                if (record.projectStatus === QUALITY_CHECK) {
                  setProject(record);
                  setVisibleFeedbackDialog(true);
                } else {
                  if (record.qualityChecker == null && record.employee.id != currentUser.id && record.parentId == null) {
                    confirmDialog({
                      message: "Do you want to assign yourself to Quality Checker?",
                      header: "Confirmation",
                      icon: "pi pi-exclamation-triangle",
                      accept: () => handleFinalCheckProject(record, true),
                      reject: () => handleFinalCheckProject(record, false),
                    });
                  } else {
                    confirmDialog({
                      message: "Are you sure you want to proceed?",
                      header: "Confirmation",
                      icon: "pi pi-exclamation-triangle",
                      accept: () => handleFinalCheckProject(record, false),
                    });
                  }
                }
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToProjectForAdjustmentRequired(record, permissions) ? (
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Customer request change"
              onClick={(e) => {
                e.stopPropagation();
                setApiLoading(true);
                setProject(record);
                setVisibleCustomerRequestChangeDialog(true);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToProjectForMakeChanges(record, permissions) ? (
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Make Changes"
              onClick={(e) => {
                e.stopPropagation();
                confirmDialog({
                  message: "Are you sure you want to proceed?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => handleMakeChangesProject(record),
                });
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToProjectForAccept(record, permissions) ? (
          <div>
            <Button
              style={{ width: "120px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Accept"
              onClick={(e) => {
                e.stopPropagation();
                setProject(record);
                setVisibleFeedbackDialog(true);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToProjectForRequestChanges(record, permissions) ? (
          <div>
            <Button
              style={{ width: "120px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Request Changes"
              onClick={(e) => {
                e.stopPropagation();
                confirmDialog({
                  message: "Are you sure you want to proceed?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => customerChangesRequestedProject(record),
                });
              }}
            />
          </div>
        ) : (
          ""
        )}

        {conditionToReturnToFinalCheck(
          record,
          permissions
        ) ? (
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Return to final check"
              onClick={(e) => {
                e.stopPropagation();
                confirmDialog({
                  message: "Are you sure you want to proceed?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => handleReturnToFinalCheck(record),
                });
              }}
            />
          </div>
        ) : (
          ""
        )} 

        {conditionToProjectForDelivered(record, permissions) ? (
          <div>
            <Button
              style={{ width: "120px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Delivered"
              onClick={(e) => {
                e.stopPropagation();
                confirmDialog({
                  message: "Are you sure you want to proceed?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => managerDeliverProject(record),
                });
              }}
            />
          </div>
        ) : (
          ""
        )}
        {conditionToCopyProject(record, permissions) ? (
          <div>
            <Button
              style={{ width: "120px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Copy Project"
              onClick={(e) => {
                e.stopPropagation();
                confirmDialog({
                  message: "Are you sure you want to copy this project?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => handleCopyProject(record),
                });
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      
    );
  };

  const changeVisibleContractorDialogState = (bool) => {
    setApiLoading(false);
    fetchProjectCount();
    setProject(null);
    setVisibleContractorDialog(bool);
  };

  const changeVisibleQualityCheckerDialogState = (bool) => {
    setApiLoading(false);
    fetchProjectCount();
    setProject(null);
    setVisibleQualityCheckerDialog(bool);
  };

  const changeVisibleManagerDialogState = (bool) => {
    setApiLoading(false);
    fetchProjectCount();
    setProject(null);
    setVisibleManagerDialog(bool);
  };

  const onGlobalFilterChange = (event) => {
    if (event.key === "Enter") {
      searchProject(globalFilterValue).then((res) => {
        setApiLoading(true);
        if (res.status === 200) {
          if (res.data.length > 0) {
            setProjects(res.data);
            searchProjectCount(globalFilterValue).then((countRes) => {
              if (countRes.status === 200) {
                setTotalRecords(countRes.data);
                setApiLoading(false);
              } else setApiLoading(false);
            });
          } else {
            setApiLoading(false);
            setProjects([]);
          }
        } else {
          setProjects([]);
          setApiLoading(false);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <Spin spinning={apiLoading}>
          <div>
            <Toolbar
              className="mb-4"
              left={() => {
                return (
                  <React.Fragment>
                    <div>
                      <h3 className="m-0">Project Details</h3>
                    </div>
                  </React.Fragment>
                );
              }}
              right={() => {
                return (
                  <React.Fragment>
                    <span className="p-input-icon-left p-input-icon-right">
                      <i
                        className="pi pi-search"
                        // style={{cursor:"pointer"}}
                      />
                      <InputText
                        value={globalFilterValue}
                        // onKeyUp= {onGlobalFilterChange}
                        onKeyPress={onGlobalFilterChange}
                        onChange={(e) => {
                          const value = e.target.value;
                          setGlobalFilterValue(value);
                        }}
                        placeholder="Address Search"
                      />
                      <i
                        className="pi pi-times"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setGlobalFilterValue(" ");
                          fetchProjectCount();
                        }}
                      />
                    </span>
                  </React.Fragment>
                );
              }}
            ></Toolbar>
          </div>
          <div>
            {projects?.map((row) => {
              // Determine if priorityCustomer should be true
              const showMessage =
                (currentUser?.userType !== "Customer" && row.projectMessage != null) ||
                (currentUser?.userType === "Customer" && row.customerMessage != null);
              return (
                <Tooltip
                  key={row?.projectId}
                  target={`.tooltip-row-${row?.projectId}`} // Unique class for each row
                  content={showMessage ? (currentUser?.userType !== "Customer" ? `${row?.projectMessage}` : `${row?.customerMessage}`) : ""} // Conditional Tooltip
                  position="top left"
                />
              );
            })}
            <DataTable
              rowHover // Enables row hover effect
              rowClassName={rowClassName}
              ref={dt}
              value={projects}
              dataKey="id"
              responsiveLayout="scroll"
              selectionMode="single"
              rowKey={projects}
              onRowClick={(e) => {
                window.localStorage.setItem(PROJECT_IDS, projects.map(t => t.id));
                history.push({
                  pathname: `${e.data.id}/projectEdit`,
                  state: {
                    id: e.data.id,
                  },
                });
              }}
            >
              <Column
                field="projectId"
                header={<span>ID</span>}
                body={(record) => (
                  <span style={{ whiteSpace: "nowrap" }}>
                    {record?.overdueProject ? (
                      <span style={{ color: "red", fontWeight: "bold" }}>! {record.projectId}</span>
                    ) : (
                      record.projectId
                    )}
                  </span>
                )}
              />
              <Column
                field="customer"
                header="Owner"
                body={(record) => {
                  return (
                    <React.Fragment>{record?.customer?.name}</React.Fragment>
                  );
                }}
              ></Column>
              <Column
                field="manager"
                header="Manager"
                body={(record) => {
                  return (
                    <React.Fragment>{record?.manager?.name}</React.Fragment>
                  );
                }}
              ></Column>
                <Column
                field="customer"
                header="Customer"
                body={(record) => {
                  return (
                    <React.Fragment>{record?.company?.name}</React.Fragment>
                  );
                }}
              ></Column>
              <Column
                field="employee"
                header="Contractor"
                body={(record) => {
                  return (
                    <React.Fragment>{record?.employee?.name}</React.Fragment>
                  );
                }}
              ></Column>
              <Column
                field="qualityChecker"
                header="Quality Checker"
                body={(record) => {
                  return (
                    <React.Fragment>
                      {record?.qualityChecker?.name}
                    </React.Fragment>
                  );
                }}
              ></Column>
              <Column
                header="Rework Type"
                body={(record) => {
                  if (record?.parentId != null)
                    return (
                      <React.Fragment>
                        Rework
                      </React.Fragment>
                    );
                  else if (record?.reworkProjects?.length > 0)
                    return <React.Fragment>
                      Original
                    </React.Fragment>
                  else
                    return <React.Fragment>
                    </React.Fragment>
                }}
              ></Column>
              <Column
                // field="importStatus"
                header="Import Status"
                body={(record) => {
                  if (
                    record?.import2d === 0 &&
                    record?.import3d === 0 &&
                    record?.importBackground === 0
                  )
                    return <React.Fragment></React.Fragment>;
                  else if (
                    record?.import2d === 1 ||
                    record?.import3d === 1 ||
                    record?.importBackground === 1
                  )
                    return (
                      <React.Fragment>
                        <img width={25}
                          src={inProgress}
                          alt="In Progress"
                        />
                      </React.Fragment>
                    );
                  else if (
                    record?.import2d === 2 &&
                    record?.import3d === 2 &&
                    record?.importBackground === 2
                  )
                    return (
                      <React.Fragment>
                        <img width={25}
                          src={ready}
                          alt="Ready"
                        />
                      </React.Fragment>
                    );
                }}
              ></Column>
              <Column align="center" field="product" header="Product"></Column>
              <Column
                align="left"
                // field="street"
                header="Project Address"
                body={(record) => {
                  return (
                    <React.Fragment>
                      {record &&
                      (record.street != null ||
                        record.number != null ||
                        record.city != null)
                        ? record.street +
                          " " +
                          record.number +
                          " " +
                          record.city
                        : ""}
                    </React.Fragment>
                  );
                }}
              ></Column>

              <Column
                align="center"
                field="houseType"
                header="House Type"
              ></Column>
              <Column
                field="ndd"
                header="NDD"
                body={(record) => {
                  return record?.ndd === "Yes" ? (
                    <React.Fragment>
                      <div style={{ border: "2px solid red" }}>
                        <b>{record?.ndd}</b>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>{record?.ndd}</React.Fragment>
                  );
                }}
              />
              <Column
                field="autocad"
                header="Is AutoCad"
                body={(record) => {
                  return record?.agency?.format === "Autocad" ? (
                    <React.Fragment>
                        <div style={{ border: "2px solid red", width:"27px" }}>
                        <b>Yes</b>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>No</React.Fragment>
                  );
                }}
              />
              <Column
                field="agency"
                header="Agency"
                body={(record) => {
                  return (
                    <React.Fragment>
                      {record?.agency?.name + " " + record?.agency?.postCode}
                    </React.Fragment>
                  );
                }}
              ></Column>
              <Column
                align="center"
                field="projectStatus"
                header="Status"
                body={(record) => {
                  switch (record?.projectStatus) {
                    case SUBMITTED:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{ width: "125px" }}
                            className="mr-2 text-lg"
                            severity="info"
                            value="Submitted"
                          ></Tag>
                        </React.Fragment>
                      );
                    case IN_PROGRESS:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{ width: "125px" }}
                            className="mr-2 text-lg"
                            value="In Progress"
                          ></Tag>
                        </React.Fragment>
                      );
                    case FINAL_CHECK:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{
                              width: "125px",
                              backgroundColor: "#C79805",
                            }}
                            className="mr-2 text-lg"
                            severity="warning"
                            value="Final Check"
                          ></Tag>
                        </React.Fragment>
                      );
                    case MAKE_CHANGES:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{ width: "125px" }}
                            className="mr-2 text-lg"
                            severity="danger"
                            value="Make Changes"
                          ></Tag>
                        </React.Fragment>
                      );
                    case ACCEPTED:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{
                              width: "125px",
                              backgroundColor: "#059BB4",
                            }}
                            className="mr-2 text-lg"
                            severity="success"
                            value="Accepted"
                          ></Tag>
                        </React.Fragment>
                      );
                    case QUALITY_CHECK:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{ width: "125px" }}
                            className="mr-2 text-lg"
                            severity="warning"
                            value="Quality Check"
                          ></Tag>
                        </React.Fragment>
                      );
                    case ASSIGNED:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{ width: "125px" }}
                            className="mr-2 text-lg"
                            severity="info"
                            value="Assigned"
                          ></Tag>
                        </React.Fragment>
                      );
                    
                      case ASSIGN:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{ width: "125px" }}
                              className="mr-2 text-lg"
                              severity="info"
                              value="Assign"
                            ></Tag>
                          </React.Fragment>
                        );
                    case ADJUSTMENTS_REQUESTED:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{ width: "125px" }}
                            className="mr-2 text-lg"
                            severity="danger"
                            value="Adjustments Requested"
                          ></Tag>
                        </React.Fragment>
                      );
                    case DELIVERED:
                      return (
                        <React.Fragment>
                          <Tag
                            style={{ width: "125px" }}
                            className="mr-2 text-lg"
                            severity="success"
                            value="Delivered"
                          ></Tag>
                        </React.Fragment>
                      );
                    default:
                      break;
                  }
                }}
              ></Column>
              <Column
                align="center"
                field="eta"
                header="ETA"
                body={(record) => {
                  return compareEta(
                    record,
                    etaDateDisplay(currentUser, record)
                  );
                }}
              ></Column>
              <Column
                align="center"
                field="creationDate"
                header="Creation Date"
              ></Column>
              <Column
                align="center"
                field="measurementDate"
                header="Measurement Date"
              ></Column>
              <Column
                field="action"
                header="Action"
                body={(record) => {
                  return (
                    <React.Fragment>
                      <div className="flex justify-content-center">
                        <div>
                          <Popover
                            placement="leftBottom"
                            content={() => content(record)}
                          >
                            <Button
                              type="button"
                              icon="pi pi-ellipsis-v"
                              aria-haspopup
                              aria-controls="overlay_panel"
                              className="select-product-button"
                              style={{ backgroundColor: "#037eea" }}
                            />
                          </Popover>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }}
              ></Column>
            </DataTable>
            <Paginator
              first={basicFirst}
              rows={totalRows}
              totalRecords={totalRecords}
              rowsPerPageOptions={[30, 60, 90]}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </Spin>
        <AssignToContractorDialog
          visible={visibleContractorDialog}
          setVisible={changeVisibleContractorDialogState}
          project={project}
        />

        <AssigntoqualityCheckerDialog
          visible={visibleQualityCheckerDialog}
          setVisible={changeVisibleQualityCheckerDialogState}
          project={project}
        />

        <AssignToManagerDialog
          visible={visibleManagerDialog}
          setVisible={changeVisibleManagerDialogState}
          project={project}
        />

        <CustomerRequestChangeDialog
          visible={visibleCustomerRequestChangeDialog}
          setVisible={changeVisibleCustomerRequestChangeDialogState}
          project={project}
        />
        <FeedbackDialog
        visible={visibleFeedbackDialog}
        setVisible={changeVisibleFeedbackDialogState}
        project={project}
        currentUser={currentUser}
      />
      </div>
    </React.Fragment>
  );
};
export default ProjectDetail;
