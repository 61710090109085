import React, { useEffect, useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Spin } from "antd";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { deleteSpecificAgency, getAllAgencies } from "../../apis/AgencyApis";
import { useSelector } from "react-redux";
import { ADD_AGENCY, DELETE_AGENCY, EDIT_AGENCY } from "../../utils/constants";
import AddAgencyDialog from "./dialog/addagencydialog";
import AddAgencyformatDialog from "./dialog/addagencyformatdialog";

const Agency = () => {
  const [agencies, setAgencies] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [visibleAgencyDialog, setVisibleAgencyDialog] = useState(false);
  const [visibleAgencyFormatDialog, setVisibleAgencyFormatDialog] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const dt = useRef(null);
  const toast = useRef(null);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const loadAllAgencies = () => {
    setApiLoading(true);
    getAllAgencies()
      .then((res) => {
        if (res.status === 200) {
          setAgencies(res.data);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  useEffect(() => {
    loadAllAgencies();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const deleteAgency = (id) => {
    if (id) {
      setApiLoading(true);
      deleteSpecificAgency(id)
        .then((res) => {
          if (res.status === 200) {
            setApiLoading(false);
            loadAllAgencies();
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Agency Deleted Successfully",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    }
  };

  const changeVisibleAgencyDialog = (bool) => {
    setVisibleAgencyFormatDialog(bool)
    setSelectedAgency(false);
    setApiLoading(false);
    if (bool === false) {
      loadAllAgencies();
    }
    setVisibleAgencyDialog(bool);
    
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <div>
          <Spin spinning={apiLoading}>
            <div>
              <Toolbar
                className="mb-4"
                left={() => {
                  return (
                    <React.Fragment>
                      <div>
                        <h3 className="m-0">Agency Details</h3>
                      </div>
                    </React.Fragment>
                  );
                }}
                right={() => {
                  return (
                    <React.Fragment>
                      {permissions.map((permission) => {
                        return permission.name === ADD_AGENCY ? (
                          <React.Fragment>
                            <div style={{marginRight:"5px"}}>
                            <Button
                              style={{ backgroundColor: "#037eea" }}
                              icon="pi pi-plus"
                              label="Agency Format"
                              onClick={(e) => {
                                e.stopPropagation();
                                setVisibleAgencyFormatDialog(true);
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              style={{ backgroundColor: "#037eea" }}
                              icon="pi pi-plus"
                              label="New"
                              onClick={(e) => {
                                e.stopPropagation();
                                
                                document.body.style.overflow = "hidden";
                                setVisibleAgencyDialog(true);
                              }}
                            />
                          </div>
                          
                          </React.Fragment>
                          
                        ) : (
                          ""
                        );
                      })}
                    </React.Fragment>
                  );
                }}
              ></Toolbar>
            </div>
            <div>
              <DataTable
                ref={dt}
                value={agencies}
                dataKey="id"
                responsiveLayout="scroll"
                size="small"
              >
                <Column field="company.name" header="Customer"></Column>
                <Column field="name" header="Name"></Column>
                <Column field="reportName" header="Report Name"></Column>
                <Column field="address" header="Address"></Column>
                <Column field="postCode" header="Post Code"></Column>
                <Column field="city" header="City"></Column>
                <Column field="agencyFormat" header="Agency Format" body={(record) => {
                  return (
                    <React.Fragment>
                      {record?.agencyFormat?.name}
                    </React.Fragment>
                  );
                }}></Column>
                <Column field="format" header="Format"></Column>
                <Column
                  field="Action"
                  header="Action"
                  body={(record) => {
                    return (
                      <React.Fragment>
                        <div className="flex justify-content-center gap-2">
                          {permissions.map((permission) => {
                            return permission.name === EDIT_AGENCY ? (
                              <div>
                                <Button
                                  style={{ backgroundColor: "#037eea" }}
                                  icon="pi pi-pencil"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    document.body.style.overflow = "hidden";
                                    setSelectedAgency(record);
                                    setVisibleAgencyDialog(true);
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            );
                          })}
                          {permissions.map((permission) => {
                            return permission.name === DELETE_AGENCY ? (
                              <div>
                                <Button
                                  className="p-button-danger"
                                  icon="pi pi-trash"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteAgency(record.id);
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  }}
                ></Column>
              </DataTable>
            </div>
          </Spin>
        </div>

        <AddAgencyDialog
          visible={visibleAgencyDialog}
          setVisible={changeVisibleAgencyDialog}
          agency={selectedAgency ? selectedAgency : null}
        />
        <AddAgencyformatDialog
        visible={visibleAgencyFormatDialog}
        setVisible={changeVisibleAgencyDialog}/>
      </div>
    </React.Fragment>
  );
};

export default Agency;
