import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Select, Spin, Radio } from "antd";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import {
  addNewAgency,
  getFormatFiles,
  getAllAgenciesFormats,
} from "../../../apis/AgencyApis";
import { getAllCompanies } from "../../../apis/CompanyApis";
import { useSelector } from "react-redux";
import { USER_TYPE_OWNER } from "../../../utils/constants";


const AddAgencyDialog = (props) => {
  const [agencyFormatImage, setagencyFormatImage] = useState(null);
  const [agencyFormats, setAgencyFormats] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [agencyForm] = Form.useForm();
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [companies, setCompanies] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const toast = useRef(null);
  const { Option } = Select;

  const dropdownStyles = {
    zIndex: 10000,
    minWidth: "270px"
  };
  useEffect(() => {
    fetchCompanies();
  }, []);


  const fetchCompanies = () => {
    setApiLoading(true);
    getAllCompanies()
      .then((res) => {
        if (res.status === 200) {
          setCompanies(res.data);
          let options = (res.data).map((r) => (
            { value: r.id, label: r.name }
          ))
          setCompanyOptions(options);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  }

  const getFormatImage = (name) => {
    setApiLoading(true);
    getFormatFiles(name)
      .then((res) => {
        if (res.status === 200) {
          setagencyFormatImage(res);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        setApiLoading(false);
      });
  };

  const getAllFormat = () => {
    setApiLoading(true);
    getAllAgenciesFormats()
      .then((res) => {
        if (res.status === 200) {
          setAgencyFormats(res.data);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        setApiLoading(false);
      });
  };

  useEffect(() => {
    getAllFormat();
    if (props.visible && props.agency !== null) {
      getFormatImage(props.agency.agencyFormat.id);
      agencyForm.setFieldsValue({
        ...props.agency,
        agencyFormat: props.agency.agencyFormat.id,
        companyId: props.agency.company.id
      });
      setSelectedCompanyId(props.agency.company.id);
    } else {
      agencyForm.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const handleSubmit = () => {
    agencyForm.validateFields().then((values) => {
      let agencyFormat = agencyFormats.filter(
        (format) => format.id === values.agencyFormat
      );
      let companyId = currentUser.userType === USER_TYPE_OWNER ? currentUser.company.id : values.companyId

      let company = companies.filter((c) => c.id === companyId)
      setApiLoading();
      let apiValues = {
        id: agencyForm.getFieldValue("id")
          ? agencyForm.getFieldValue("id")
          : null,
        agencyFormat: {...agencyFormat[0], company: {id: agencyFormat[0]?.company?.id}},
        address: values.address,
        city: values.city,
        format: values.format,
        name: values.name,
        postCode: values.postCode,
        company: {id: company[0]?.id},
        reportName: values.reportName
      };
      addNewAgency(apiValues)
        .then((res) => {
          if (res.status === 200) {
            agencyForm.resetFields();
            setagencyFormatImage(null);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Agency Add/Edit Succesfully",
              life: 3000,
            });
            props.setVisible(false);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    });
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <Dialog
          header="Add/Edit Agency"
          visible={props.visible}
          modal
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw" }}
          onHide={() => {
            document.body.style.overflow = "scroll";
            agencyForm.resetFields();
            setagencyFormatImage(null);
            props.setVisible(false);
          }}
        >
          <Spin spinning={apiLoading}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <Form
                  form={agencyForm}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  size="middle"
                  className="custom-form-design"
                  onFinish={handleSubmit}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Agency Name is required.",
                      },
                    ]}
                    name="name"
                    label="Agency Name"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        message: "Agency Report Name is required.",
                      },
                    ]}
                    name="reportName"
                    label="Agency Report Name"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Address is required.",
                      },
                    ]}
                    name="address"
                    label="Address"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Post Code is required.",
                      },
                    ]}
                    name="postCode"
                    label="Post Code"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "City is required.",
                      },
                    ]}
                    name="city"
                    label="City"
                  >
                    <Input />
                  </Form.Item>
                  {currentUser.userType !== "Customer" && (
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Customer is required.",
                        },
                      ]}
                      name="companyId"
                      label="Customer"
                    >
                      <Select
                        style={{ width: "270" }}
                        mode={"single"}
                        dropdownStyle={dropdownStyles}
                        placeholder="Select Customer"
                        options={companyOptions}
                        onChange={(e) => { setSelectedCompanyId(e) }}
                      >
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    name="agencyFormat"
                    label="Agency Formats"
                    rules={[
                      {
                        required: true,
                        message: "Agency Format is required.",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      onChange={(e) => {
                        getFormatImage(e);
                      }}
                      dropdownStyle={{ zIndex: "9999" }}
                    >
                      {agencyFormats?.filter(item => selectedCompanyId == null || item?.company?.id == selectedCompanyId).map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="format" label="Format">
                    <Radio.Group defaultValue={"floorplanner"}>
                      <Radio value="floorplanner">floorplanner</Radio>
                      <Radio value="Autocad">Autocad</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Button
                    style={{
                      backgroundColor: "#037eea",
                      borderColor: "#037eea",
                      float: "right",
                      marginRight: "1rem",
                    }}
                    type="submit"
                    label="Submit"
                  />
                </Form>
              </div>
              <div className="p=fluid card flex align-items-center">
                <div style={{ width: 270 }}>
                  {agencyFormatImage && agencyFormatImage.data ? (
                    <Image
                      src={URL.createObjectURL(agencyFormatImage.data)}
                      alt="Image"
                      width="270"
                      preview
                    />
                  ) : (
                    <h5 style={{ textAlign: "center" }}>
                      No Preview Available
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </Spin>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default AddAgencyDialog;
