import React, { useEffect, useRef, useState } from "react";

import {
    Spin
} from "antd";
import { TabPanel, TabView } from "primereact/tabview";
import CompanyConfig from "./CompanyConfig";
import InvoiceConfig from "./InvoiceConfig";
import UserInvoiceConfig from "./UserInvoiceConfig";
import AdjustmentRateConfig from "./AdjustmentRateConfig";
import ReportOrderComponent from "./ReportOrder";
import ProjectCapacity from "./ProjectCapacity";
import GlobalConfig from "./CustomValue";
import CustomValue from "./CustomValue";

const Admin = () => {
    const [apiLoading, setApiLoading] = useState(false);

    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        identifyTabs();
    }, [])

    const identifyTabs = () => {
        const newTabsArray = [];
        newTabsArray.push({
            title: "Global Config",
            content: (
                <CustomValue
                    loading={apiLoading}
                    setLoading={(e) => {
                        setApiLoading(e);
                    }}
                />
            ),
        });

        newTabsArray.push({
            title: "Assigning Page",
            content: (
                <ProjectCapacity
                    loading={apiLoading}
                    setLoading={(e) => {
                        setApiLoading(e);
                    }}
                />
            ),
        });

        newTabsArray.push({
            title: "Company Config",
            content: (
                <CompanyConfig
                    loading={apiLoading}
                    setLoading={(e) => {
                        setApiLoading(e);
                    }}
                />
            ),
        });
        newTabsArray.push({
            title: "Project Invoice Config",
            content: (
                <InvoiceConfig
                    loading={apiLoading}
                    setLoading={(e) => {
                        setApiLoading(e);
                    }}
                />
            ),
        });

        newTabsArray.push({
            title: "User Invoice Config",
            content: (
                <UserInvoiceConfig
                    loading={apiLoading}
                    setLoading={(e) => {
                        setApiLoading(e);
                    }}
                />
            ),
        });

        newTabsArray.push({
            title: "Rework Config",
            content: (
                <AdjustmentRateConfig
                    loading={apiLoading}
                    setLoading={(e) => {
                        setApiLoading(e);
                    }}
                />
            ),
        });
        newTabsArray.push({
            title: "Order Of Report Config",
            content: (
                <ReportOrderComponent
                    loading={apiLoading}
                    setLoading={(e) => {
                        setApiLoading(e);
                    }}
                />
            ),
        });
        setTabs(newTabsArray);
    };


    return (
        <div>
            <Spin spinning={apiLoading}>
                <TabView>
                    {tabs.map((tab) => {
                        return (
                            <TabPanel key={tab.title} header={tab.title}>
                                {tab.content}
                            </TabPanel>
                        );
                    })}
                </TabView>
            </Spin>
        </div>
    );
};

export default Admin;
