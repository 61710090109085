import { Form, Spin, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import {
    addReportConfig
} from "../../../apis/CompanyApis";

const AddReportConfig = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({...props.updateReport});
    }, [props.updateReport])

    const addConfig = () => {
        setApiLoading(true);
        form.validateFields().then((values) => {
            values = {...values, id: props.updateReport?.id}
            console.log(JSON.stringify(values));
            addReportConfig(values)
                .then((res) => {
                    if (res.status === 200) {
                        props.setVisible(false);
                        setApiLoading(false);

                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: "Add report config failed",
                            life: 3000,
                        });
                        setApiLoading(false);
                    }
                })
                .catch((e) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: e,
                        life: 3000,
                    });
                    setApiLoading(false);
                });
        })

    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Dialog
                header={"Add Report Config"}
                visible={props.visible}
                style={{ minWidth: "420px" }}
                onHide={() => { props.setVisible(false) }}
            >
                <Spin spinning={apiLoading} >
                    <React.Fragment>
                        <div className='flex justify-content-between align-items-center'>
                            <Form form={form} onFinish={addConfig}>
                                <Form.Item
                                    required={false}
                                    name="name" label="Floor Name Dutch"
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 15 }}
                                    labelAlign={"left"}
                                >
                                    <Input placeholder="Enter Name" style={{ minWidth: "200px" }}>
                                    </Input>
                                </Form.Item>
                                <Form.Item
                                    required={false}
                                    name="nameRule" label="Name Rule"
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 15 }}
                                    labelAlign={"left"}
                                >
                                    <Input placeholder="Enter Name Rule" style={{ minWidth: "200px" }}>
                                    </Input>
                                </Form.Item>
                                <Form.Item
                                    required={false}
                                    name="manualName" label="Manual Name"
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 15 }}
                                    labelAlign={"left"}
                                >
                                    <Input placeholder="Enter Manual Name" style={{ minWidth: "200px" }}>
                                    </Input>
                                </Form.Item>
                                <Form.Item
                                    required={false}
                                    name="englishName" label="English Name"
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 15 }}
                                    labelAlign={"left"}
                                >
                                    <Input placeholder="Enter English Name" style={{ minWidth: "200px" }}>
                                    </Input>
                                </Form.Item>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <PrimeBtn
                                        style={{
                                            backgroundColor: "#037eea",
                                            borderColor: "#037eea",
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        type="submit"
                                        label="Submit"
                                    />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                </Spin>

            </Dialog>
        </React.Fragment>
    )
}

export default AddReportConfig
