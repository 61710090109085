import { Button, Form, Radio, Select, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  export2DProjectOnFloorPlanner,
  exportProjectOnFloorPlanner,
} from "../../../../apis/floorPlannerApis";
import "./params2dForm.scss";
import { confirmDialog } from "primereact/confirmdialog";
import { useRef } from "react";
import { Option } from "antd/lib/mentions";

const Params2dForm = (props) => {
  const [includeFML, setIncludeFML] = useState(false);
  const [form] = Form.useForm();
  const toast = useRef(null);
  useEffect(() => {
    form.setFieldsValue(
      { floors: props.selectedFloors }
    )
  }, [props.selectedFloors])

  const onFinish = (values) => {
    export2DProjectOnFloorPlanner(
      props.floorPlannerProjectId,
      props.projectId,
      values
    ).then((response) => {
      if (response.status === 200) {
        props.projectImport();
        message.success("Project imported successfully");
      }
    });
    props.visible(false);
  };

  const exportProjectonnFloorPlanner = (values) => {
    exportProjectOnFloorPlanner(
      props.floorPlannerProjectId,
      props.projectId,
      { floors: values.floors,
        visual: values.visual
       }
    ).then((response) => {
      if (response.status === 200) {
        props.projectImport();
        message.success("Project imported successfully");
      }
    });
    props.visible(false);
    message.info("Project import is processing!");
  };

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 17,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
  };

  const onSwitchChange = (checked) => {
    setIncludeFML(checked);
  };

  return (
    <React.Fragment>
      <Form form={form}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <div className="style-change">
          <Form.Item
            className="orientation-label"
            name="orientation"
            label="Orientation"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group className="radio-btns">
              <Radio.Button value="landscape">Landscape</Radio.Button>
              <Radio.Button value="portrait">Portrait</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item label="Include FML" name="includeFML">
          <Switch className="radio-btns" onChange={onSwitchChange} />
        </Form.Item>
        <Form.Item
          label="Color mode"
          name="visual"
        >
          <Select
            onChange={(e) => {
            }}
            style={{ width: 175 }}
            placeholder="Select"
            defaultValue={"BWC"}
            dropdownStyle={{ zIndex: 10000 }}>
            <Option value="BW">Black and white</Option>
            <Option value="BWC">Color</Option>
            <Option value="ALL">Material</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Floors" name="floors">
          <Select
            mode="multiple"
            onChange={(e) => {
            }}
            options={props.floorOptions}
            style={{ width: 175 }}
            placeholder="Select"
            dropdownStyle={{ zIndex: 10000 }}>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            style={{
              width: "80%",
              marginLeft: "28%",
            }}
            type="primary"
            onClick={(e) => {
              form.validateFields().then(() => {
                confirmDialog({
                  message: "Are you sure you want to import?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => onFinish(form.getFieldsValue()),
                });
              }).catch(errorInfo => {
                toast.current.show({
                  severity: "info",
                  summary: "Status",
                  detail: errorInfo,
                  life: 5000,
                });
              });
            }
            }
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <div>
        {props.containsNEN && (
          <p style={{ color: "red", fontSize: 11, marginLeft: 70, marginBottom: 10 }}>
            Project contains NEN floor. It will be removed when importing the total project.
          </p>
        )}
      </div>
      <Button
        type="primary"
        style={{
          width: "57%",
          marginLeft: "20%",
        }}
        onClick={(e) => {
          confirmDialog({
            message: "Are you sure you want to import?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => exportProjectonnFloorPlanner(form.getFieldsValue()),
          });
        }
        }      >
        Import total project
      </Button>
    </React.Fragment>
  );
};

export default Params2dForm;
